<template>
  <div>
    <Main />
    <Eco />
  </div>
</template>
<script>

import Main from "../components/farm-ECO/Main.vue";
import Eco from "../components/home/Eco.vue";
export default {
  components: {
    Main,
    Eco,
  },
};
</script>
